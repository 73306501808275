import React from 'react'
import { RichText } from 'prismic-reactjs'

import { getAnimation } from '../../utils'

import Feature from "../Feature"

import './styles.css'

const isSimple = (point) => !point?.point_info?.raw?.length && !point?.point_image?.url 

const isNoButton = (point) => !point?.point_info?.raw?.length && point?.point_image?.url 

const FeatureSimple = ({ point }) => (
  <div {...getAnimation(0)}>
    <RichText render={point?.point_title?.raw} />
    <RichText render={point?.point_description?.raw} />
  </div>
)

const FeatureWithColumns = ({ point }) => (
  <>
    <div className="feature__content">
      <div className="feature__text" {...getAnimation(200)}>
        <RichText render={point?.point_title?.raw} />
        <RichText render={point?.point_description?.raw} />
      </div>
      <FeatureImage {...{ point }} />
    </div>
    <FeatureColumns {...{ point }} />
  </>
)

const FeatureImage = ({ point }) => {
  if (!point?.point_image?.url) return null

  return (
    <div className="feature__image" {...getAnimation(400)}>
      <img src={point.point_image.url} alt={point.point_image.alt} />
    </div>
  )
}

const FeatureColumns = ({ point }) => {
  if (!point?.point_info?.raw) return null

  return (
    <div className="feature__columns" {...getAnimation(0, 'fade')}>
      <RichText render={point?.point_info?.raw} />
    </div>
  )
}

const FeatureContent = ({ point }) => {
  if (isSimple(point)) return <FeatureSimple {...{ point }} />
  return <FeatureWithColumns {...{ point }} />
}

const Features = ({ points = [] }) => points.map((point, index) => (
  <Feature
    key={`feature-${index}`}
    id={`feature-${index}`}
    number={index + 1}
    simple={isSimple(point)}
    noButton={isNoButton(point)}
  >
    <FeatureContent {...{ point }} />
  </Feature>
))

const MetodoContent = ({ data }) => (
  <div className="metodo content">
    <div className="lead" {...getAnimation(0)}>
      <RichText render={data?.intro?.raw} />
    </div>
    <ul className="features">
      <Features points={data?.points} />
    </ul>
  </div>
)

export default MetodoContent