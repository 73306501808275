import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import MetodoInfo from "../components/MetodoInfo"
import MetodoContent from "../components/MetodoContent"

import '../styles/index.css'

const Metodo = ({ location, data }) => (
  <Layout pathname={location?.pathname}>
    <SEO title="Método" />
    <div className="sidebar sidebar--left sidebar--only-desktop">
      <MetodoInfo data={data?.allPrismicDireccion?.nodes?.[0]?.data} />
    </div>
    <MetodoContent data={data?.allPrismicMetodo?.nodes?.[0]?.data}/>
    <div className="sidebar sidebar--right sidebar--only-desktop">
      &nbsp;
    </div>
  </Layout>
)

export const query = graphql`
  query {
    allPrismicDireccion {
      nodes {
        data {
          address {
            html
            text
            raw
          }
          title {
            html
            text
            raw
          }
        }
      }
    }
    allPrismicMetodo {
      nodes {
        data {
          intro {
            html
            text
            raw
          }
          points {
            point_description {
              html
              text
              raw
            }
            point_title {
              html
              text
              raw
            }
            point_image {
              alt
              url
            }
            point_info {
              html
              raw
              text
            }
          }
          title {
            html
            text
            raw
          }
        }
      }
    }
  }
`

export default Metodo
