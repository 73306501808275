import React, { useState, useEffect } from 'react'

import Button from '../Button'

import './styles.css'

const ButtonUI = ({ onClick, noButton, buttonText }) => {
  if (noButton) return <div className="feature__margin"></div>

  return (
    <div className="feature__button">
      <Button {...{ onClick }}>{buttonText}</Button>
    </div>
  )
}

const Feature = ({ children, id, simple, noButton }) => {
  const [isOpen, setIsOpen] = useState(false)

  const scrollFocus = (id) => {
    if (!id || window === 'undefined') return

    const elm = document.getElementById(id)
    if (!elm) return 

    var top = elm.offsetTop;

    window.scrollTo({top, behavior: 'smooth'});
  }

  const onClick = () => setIsOpen(!isOpen)

  useEffect(() => {
    if (!isOpen) return 
    scrollFocus(id)
  }, [isOpen, id])

  const className = isOpen ? 'feature feature--open' : 'feature'
  const buttonText = isOpen ? 'cerrar' : 'ver más'

  if (simple) {
    return (
      <li className="feature feature--simple">
        {children}
      </li>
    )
  }

  return (
    <li {...{ id }} className={className}>
      {children}
      <ButtonUI {...{ onClick, noButton, buttonText }} />
    </li>
  )
}

export default Feature