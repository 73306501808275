import React, { useEffect, useState } from 'react'
import { RichText } from 'prismic-reactjs'

import metodoImg from '../../images/metodo.png'

import './styles.css'

const MetodoInfo = ({ data }) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const img = new Image()
    img.src = metodoImg
    img.onload = () => setLoaded(true)
  }, [])

  const getClassName = (loaded) => {
    if (!loaded) return 'metodo-info'
    return 'metodo-info metodo-info--visible'
  }

  return (
    <div className={getClassName(loaded)}>
      <div className="metodo-info__image">
        <img src={metodoImg} alt="Contacto" />
      </div>
      <address className="metodo-info__address">
        <RichText render={data?.address?.raw} />
      </address>
    </div>
  )
}

export default MetodoInfo